import axios, { AxiosError } from "axios";
import { dayjs } from "@/util/dayjs";
import qs from "qs";

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
const API_URL = `${BASE_URL}/api`;

export const api = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Enable credentials
  timeout: undefined,
  headers: {
    common: {
      "x-platform": "web",
      "x-client": "Fire Notification",
      "x-app-version": "0.0.1",
      "x-timezone": dayjs.tz.guess(),
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
  },
  paramsSerializer: (params) =>
    qs.stringify(params, {
      arrayFormat: "repeat",
      skipNulls: true,
    }),
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const domain = new URL(BASE_URL!).hostname;
  config.headers['Cookie-Domain'] = domain;
  
  return config;
});

// Enhanced response interceptor
api.interceptors.response.use(
  (response) => {
    // Handle 304 responses
    if (response.status === 304) {
      return response;
    }

    const token = response.headers.authorization;
    if (token) {
      localStorage.setItem('accessToken', token);
      api.defaults.headers.common.authorization = token;
    }
    return response;
  },
  (error: AxiosError) => {
    if (["CanceledError", "AbortError"].includes(error.name)) {
      return;
    }

    console.error('API Error:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers,
      config: {
        url: error.config?.url,
        method: error.config?.method,
        headers: error.config?.headers,
      }
    });

    if (error.response?.status === 401) {
      localStorage.removeItem('accessToken');
      window.location.href = '/auth/sign-in';
      return Promise.reject(error);
    }

    return Promise.reject({
      ...error,
      originalMessage: error.message,
      message: error.response?.data?.message ||
        "We encountered a problem trying to process your request. Please try again."
    });
  }
);
