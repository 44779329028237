"use client";
import { createContext, useContext, useEffect, useState } from "react";

interface SystemInfoContextType {
  timezone: string;
  userAgent: string;
}

const SystemInfoContext = createContext<SystemInfoContextType>({
  timezone: "UTC",
  userAgent: "",
});

export function SystemInfoProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [systemInfo, setSystemInfo] = useState<SystemInfoContextType>({
    timezone: "UTC",
    userAgent: "",
  });

  useEffect(() => {
    try {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";
      setSystemInfo({
        timezone: tz,
        userAgent:
          typeof window !== "undefined" ? window.navigator.userAgent : "",
      });
    } catch (error) {
      console.warn("Error setting timezone, using UTC:", error);
    }
  }, []);

  return (
    <SystemInfoContext.Provider value={systemInfo}>
      {children}
    </SystemInfoContext.Provider>
  );
}

export const useSystemInfo = () => useContext(SystemInfoContext);
